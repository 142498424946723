import axios from 'axios';

 // export const API_URL = 'http://apiaquamar.wenatives.com.mx/api';
 // export const API_UR = 'http://apiaquamar.wenatives.com.mx';
 
 export const API_URL = 'http://materialeszarcoapi.wenatives.com.mx/api';
 export const API_UR = 'http://materialeszarcoapi.wenatives.com.mx';

 // export const API_URL = 'http://materialestecateapi.wenatives.com.mx/api';
 // export const API_UR = 'http://materialestecateapi.wenatives.com.mx';

 // export const API_URL = 'http://localhost:8000/api';
 // export const API_UR = 'http://localhost:8000';
 

 //carpeta para el static  paper-dashboard-react

 //9-7Ee2,F~#Y=

 //laravel_nomina/storage/app/xmls
export const request = axios.create({
	baseURL 		: API_UR,
	responseType 	: 'json'
});

export const api = () => {

	let token = localStorage.getItem('session_token_portal');

	token = JSON.parse(token);

	let AuthorizationToken = token.token_type+" "+token.access_token;

	return axios.create({
		baseURL 		: API_URL,
		responseType 	: 'json',
		headers 		: {'Authorization': AuthorizationToken }

	});
}

export const api_formdata = () => {

	let token = localStorage.getItem('session_token_portal');

	token = JSON.parse(token);

	let AuthorizationToken = token.token_type+" "+token.access_token;

	return axios.create({
		baseURL 		: API_URL,
		responseType 	: 'json',
		headers 		: {'Authorization': AuthorizationToken,
							'Content-Type': 'multipart/form-data', }

	});

}

export const api_descarga = () => {

	let token = localStorage.getItem('session_token_portal');

	token = JSON.parse(token);

	let AuthorizationToken = token.token_type+" "+token.access_token;

	return axios.create({
		baseURL 		: API_URL,
		responseType 	: 'blob',
		headers 		: {'Authorization': AuthorizationToken,
							'Content-Type': 'multipart/form-data', }

	});

}